import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO title="404: Not found"/>
    <h1>Nicht gefunden.</h1>
  </Layout>
)
